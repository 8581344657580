import React from 'react';
import { Link } from 'react-router-dom';

function Pricing() {
    return (
        <>
<section className="py-24">
    <div className="container max-w-screen-xl">
        <div className="row row-grid align-items-center justify-content-between">
            <div className="col-md-6 order-md-2">
                <h1 className="ls-tight font-bolder display-5 mb-5">
                   Try us out<br />for free.
                </h1>
                
                <p className="lead mb-5">
                    When you purchase you get a pack of high quality components and page layouts to start building your website instantly.
                </p>
                
                {/* <small className="text-warning">Try free for 14 days, no credit card required.</small> */}
            </div>

            <div className="col-md-6 col-lg-5 order-md-1">
                <div className="card">
                    <span className="d-inline-block w-32 mx-auto px-4 py-1 bg-primary text-sm text-center text-white font-bold rounded-bottom">Test us out</span>

                    <div className="pt-8 px-md-20">
                        <h3 className="mb-4 font-semibold">Personal</h3>
                        
                        <div className="display-4 text-heading d-flex mb-4">
                            <div dangerouslySetInnerHTML={{
                                __html: `&dollar; <span className="">0</span>`
                            }} />

                            <div className="text-muted text-base font-semibold align-self-end ms-3">
                                <span className="d-block mt-n8"> / month</span>
                            </div>
                        </div>
                        
                        <p className="text-muted">
                            The perfect way to get started and get used to our tools.
                        </p>
                    </div>
                    
                    <div className="px-md-10 py-5">
                        <hr />
                    </div>
                    
                    <div className="card-body px-md-20 px-5 pt-0 pb-4">
                        <ul className="list-unstyled text-sm text-muted mb-7">
                            <li className="py-2 d-flex align-items-center">
                                <div className="icon icon-xs text-base icon-shape rounded-circle bg-soft-success text-success me-3">
                                    <i className="bi bi-check"></i>
                                </div>
                                
                                <p className="text-heading font-semibold">
                                    Add 2 hosting providers
                                </p>
                            </li>

                            <li className="py-2 d-flex align-items-center">
                                <div className="icon icon-xs text-base icon-shape rounded-circle bg-soft-success text-success me-3">
                                    <i className="bi bi-check"></i>
                                </div>
                                
                                <p className="text-heading font-semibold">
                                    30 minute delayed analytics
                                </p>
                            </li>

                            <li className="py-2 d-flex align-items-center">
                                <div className="icon icon-xs text-base icon-shape rounded-circle bg-soft-danger text-danger me-3">
                                    <i className="bi bi-x"></i>
                                </div>
                                
                                <p className="text-heading font-semibold">
                                    Not for teams
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="card-footer px-0 py-0 border-0 overflow-hidden">
                        <Link to={{ pathname: `https://mativecloud.com/select-plan?name=personal2021` }} target="_self" className="btn btn-lg w-full btn-primary rounded-0 disabled">
                            Get started
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="py-24 position-relative bg-surface-secondary">
    <div className="container max-w-screen-xl">
        <div className="row justify-content-center text-center mb-20">
            <div className="col-lg-6">
                <h1 className="ls-tight font-bolder display-5 mb-4">
                    Work in teams
                </h1>
                
                <p className="lead px-lg-20">
                    Choose the right pricing for you and get started with your project.
                </p>
            </div>
        </div>

        <div className="row g-0 align-items-center">
            <div className="col-lg-4">
                <div className="card rounded-4 shadow border-0">
                    <div className="pt-10 px-md-12">
                        <h3 className="text-warning font-semibold">Basic</h3>
                        
                        <div className="display-5 text-heading d-flex mb-4">
                            <div dangerouslySetInnerHTML={{
                                __html: `&dollar; <span className="">2.99</span>`
                            }} />
                            
                            <div className="text-muted text-base font-semibold align-self-end ms-3">
                                <span className="d-block mt-n7"> / month</span>
                            </div>
                        </div>
                        
                        <p className="text-muted text-sm">
                            The perfect way to get started and get used to our tools.
                        </p>
                    </div>

                    <div className="px-md-12 pt-6 pb-6">
                        <hr className="m-0" />
                    </div>
                    
                    <div className="px-md-12 px-5 pb-4">
                        <ul className="list-unstyled text-sm text-muted">
                            <li className="py-1 d-flex align-items-center">
                                <div>
                                    <span className="text-xl text-muted me-3 svg-icon svg-align-baseline">
                                        <i className="bi bi-check"></i>
                                    </span>
                                </div>
                                
                                <p className="text-muted font-semibold">
                                    Unlimited hosting providers
                                </p>
                            </li>

                            <li className="py-1 d-flex align-items-center">
                                <div>
                                    <span className="text-xl text-muted me-3 svg-icon svg-align-baseline">
                                        <i className="bi bi-check"></i>
                                    </span>
                                </div>
                                
                                <p className="text-muted font-semibold">
                                    5 minute delayed Analytics
                                </p>
                            </li>

                            <li className="py-1 d-flex align-items-center">
                                <div>
                                    <span className="text-xl text-muted me-3 svg-icon svg-align-baseline">
                                        <i className="bi bi-check"></i>
                                    </span>
                                </div>
                                
                                <p className="text-muted font-semibold">
                                    Includes 1 extra team member
                                </p>
                            </li>
                        </ul>
                        
                        <Link to={{ pathname: `https://mativecloud.com/select-plan?name=basic2021` }} target="_self" className="btn btn-lg w-full btn-primary mt-6 mb-4 disabled">
                            Get started
                        </Link>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="p-1 bg-card border border-card rounded-4 shadow-5 position-relative transform scale-110 overlap-10">
                    <div className="card bg-dark border-0 rounded-4 shadow-none">
                        <span className="d-inline-block w-20 px-2 py-1 bg-primary text-center text-xs text-white font-semibold rounded-pill position-absolute top-0 end-0 mt-4 me-4">Popular</span>
                        <div className="pt-10 px-md-12">
                            <h3 className="text-white font-semibold">Standard</h3>
                            
                            <div className="display-5 text-white d-flex mb-4">
                                <div dangerouslySetInnerHTML={{
                                    __html: `&dollar; <span className="">9.99</span>`
                                }} />

                                <div className="text-white-80 text-base font-semibold align-self-end ms-3">
                                    <span className="d-block mt-n7"> / month</span>
                                </div>
                            </div>
                            
                            <p className="text-white-80 text-sm">
                                The perfect way to get started and get used to our tools.
                            </p>
                        </div>
                        
                        <div className="px-md-12 pt-6 pb-6">
                            <hr className="opacity-10 m-0" />
                        </div>
                        
                        <div className="px-md-12 px-5 pb-4">
                            <ul className="list-unstyled text-sm text-muted">
                                <li className="py-1 d-flex align-items-center">
                                    <div>
                                        <span className="text-xl text-white me-3 svg-icon svg-align-baseline">
                                            <i className="bi bi-check"></i>
                                        </span>
                                    </div>
                                    
                                    <p className="text-white-80 font-semibold">
                                        All features in <span className="text-white">Basic</span>
                                    </p>
                                </li>
                                <li className="py-1 d-flex align-items-center">
                                    <div>
                                        <span className="text-xl text-white me-3 svg-icon svg-align-baseline">
                                            <i className="bi bi-check"></i>
                                        </span>
                                    </div>
                                    
                                    <p className="text-white-80 font-semibold">
                                       Near real-time Analytics
                                    </p>
                                </li>
                                <li className="py-1 d-flex align-items-center">
                                    <div>
                                        <span className="text-xl text-white me-3 svg-icon svg-align-baseline">
                                            <i className="bi bi-check"></i>
                                        </span>
                                    </div>
                                    
                                    <p className="text-white-80 font-semibold">
                                        Includes 5 extra team members
                                    </p>
                                </li>
                            </ul>
                            
                            <Link to={{ pathname: `https://mativecloud.com/select-plan?name=standard2021` }} target="_self" className="btn btn-lg w-full btn-primary mt-6 mb-4 disabled">
                                Get started
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="card rounded-4 shadow border-0">
                    <div className="pt-10 px-md-12">
                        <h3 className="text-info font-semibold">Extended</h3>
                        
                        <div className="display-5 text-heading d-flex mb-4">
                            <div dangerouslySetInnerHTML={{
                                __html: `&dollar; <span className="">34.99</span>`
                            }} />

                            <div className="text-muted text-base font-semibold align-self-end ms-3">
                                <span className="d-block mt-n7"> / month</span>
                            </div>
                        </div>
                        
                        <p className="text-muted text-sm">
                            The perfect way to get started and get used to our tools.
                        </p>
                    </div>
                    
                    <div className="px-md-12 pt-6 pb-6">
                        <hr className="m-0" />
                    </div>
                    
                    <div className="px-md-12 px-5 pb-4">
                        <ul className="list-unstyled text-sm text-muted">
                            <li className="py-1 d-flex align-items-center">
                                <div>
                                    <span className="text-xl text-muted me-3 svg-icon svg-align-baseline">
                                        <i className="bi bi-check"></i>
                                    </span>
                                </div>
                                
                                <p className="text-muted font-semibold">
                                    All features in <span className="text-heading">Standard</span>
                                </p>
                            </li>

                            <li className="py-1 d-flex align-items-center">
                                <div>
                                    <span className="text-xl text-muted me-3 svg-icon svg-align-baseline">
                                        <i className="bi bi-check"></i>
                                    </span>
                                </div>
                                
                                <p className="text-muted font-semibold">
                                    Includes 15 extra team members
                                </p>
                            </li>

                            <li className="py-1 d-flex align-items-center">
                                <div>
                                    <span className="text-xl text-muted me-3 svg-icon svg-align-baseline">
                                        <i className="bi bi-check"></i>
                                    </span>
                                </div>
                                
                                <p className="text-muted font-semibold">
                                    Add extra team members for &dollar; 1.49 per month
                                </p>
                            </li>
                        </ul>
                        
                        <Link to={{ pathname: `https://mativecloud.com/select-plan?name=extended2021` }} target="_self" className="btn btn-lg w-full btn-primary mt-6 mb-4 disabled">
                            Get started
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 
        </>
    );
}

export default Pricing;
