import React from 'react';
import { Switch, Route } from 'react-router-dom';

// import { Counter } from './features/counter/Counter';

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';

import './App.css';

function App() {
    return (
        <div className="overflow-x-hidden">
            <Header />

            <Switch>
                <Route path="/pricing" component={Pricing} />
                <Route path="/contact" component={Contact} />

                <Route path="/" component={Home} />
            </Switch>

            <Footer />
        </div>
    );
}

export default App;
