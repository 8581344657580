import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
    return (
    <div className="py-24 py-lg-32 py-xl-40 position-relative">
        <div className="container max-w-screen-xl">
            <div className="row align-items-center">
                <div className="col-12 col-lg-5 mb-16 mb-lg-0">
                    <h6 className="font-semibold text-uppercase ls-tight mb-5">&mdash; Mative Cloud</h6>
                    
                    <h1 className="ls-tight font-bolder display-3 mb-5">
                        Cloud Manager
                    </h1>
                    
                    <p className="lead mb-10">
                        Get a better overview of your services, in one simple dashboard. <i>Available in Q4 2021</i>
                    </p>

                    <div className="mx-n2">
                        <Link to="/" className="btn btn-lg btn-primary shadow-sm mx-2 px-lg-8 disabled">
                            Coming soon
                        </Link>
                    </div>
                    
                    {/* <div className="mt-10 mt-lg-16">
                        <h6 className="mb-3">Reviews on TrustPilot</h6>

                        <div className="d-flex mb-2">
                            <div className="w-8 h-8 d-flex align-items-center justify-content-center bg-warning text-white rounded-2 me-1">
                                <i className="bi bi-star-fill"></i>
                            </div>
                            <div className="w-8 h-8 d-flex align-items-center justify-content-center bg-warning text-white rounded-2 me-1">
                                <i className="bi bi-star-fill"></i>
                            </div>
                            <div className="w-8 h-8 d-flex align-items-center justify-content-center bg-warning text-white rounded-2 me-1">
                                <i className="bi bi-star-fill"></i>
                            </div>
                            <div className="w-8 h-8 d-flex align-items-center justify-content-center bg-warning text-white rounded-2 me-1">
                                <i className="bi bi-star-fill"></i>
                            </div>
                            <div className="w-8 h-8 d-flex align-items-center justify-content-center bg-warning text-white rounded-2 me-1">
                                <i className="bi bi-star-fill"></i>
                            </div>
                        </div>

                        <Link to="/" className="text-sm text-muted d-inline-block">See all the 250 customer reviews</Link>
                    </div> */}
                </div>
                <div className="col-12 col-lg-6 ms-lg-auto transform translate-x-lg-1/3 position-relative">
                    <span className="d-none d-lg-block position-absolute bottom-0 bottom-0 w-64 h-64 bg-info opacity-30 rounded-circle transform translate-x-n1/2 translate-y-n32 filter blur-50"></span>

                    <div className="position-relative overlap-10 transform scale-lg-150">
                        <img alt="Mative Cloud" src="https://preview.webpixels.io/web/img/other/demo/app-screen-1.webp" className="rounded-5 position-relative overlap-10" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Home;
