import React from 'react';
import { Link } from 'react-router-dom';

import Logo from './../assets/icon.svg';

function Header() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light px-0 py-3">
            <div className="container-xl max-w-screen-xl">
                <Link className="navbar-brand" to="/">
                    <img src={Logo} className="h-10" alt="Mative Logo" />
                </Link>
                
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav mx-lg-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/pricing">Pricing</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </li>
                    </ul>
                    
                    <div className="navbar-nav ms-lg-4">
                        <Link className="nav-item nav-link" to={{ pathname: "https://id.mative.org" }} target="_blank">Sign in</Link>
                    </div>
                    
                    <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
                        <Link className="btn btn-sm btn-primary w-full w-lg-auto" to={{ pathname: "https://id.mative.org" }} target="_blank">
                            Register
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;
