import React from 'react';
// import { Link } from 'react-router-dom';

function Contact() {
    return (
<div className="bg-surface-secondary">
    <div className="container py-10">
        <div className="row mb-10">
            <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                <h4 className="font-semibold mb-2">Contact us</h4>
                <p className="text-sm">You can contact us via email, we we'll respond within 1 - 3 work days.</p>
            </div>
            <div className="col-lg-8">
                <div className="card shadow border-0">
                    <div className="card-body">
                        <b>Email</b><br />
                        help@mative.org<br /><br />

                        <b>Hoog Antink.org</b><br />
                        C.P.M. Rommelaan 26,<br />
                        7103JD, Winterswijk, Netherlands<br/>
                        <i>Not a visiting address</i><br />
                        <small>Mative.org is a company by Hoog Antink.org</small><br /><br />
                        
                        IBAN: NL53 KNAB 0258 6776 78<br />
                        KvK: 74274783<br />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    );
}

export default Contact;
