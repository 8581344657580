import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
<footer className="pt-12 pb-12 bg-surface-dark text-white-80 text-white-hover">
    <div className="container max-w-screen-xl">
        <div className="row mb-12">
            <div className="col">
                <div className="card border-0 overflow-hidden bg-primary text-white-80 text-white-hover">
                    <div className="card-body">
                        <div className="position-relative d-flex flex-column flex-md-row justify-content-between align-items-center zindex-100">
                            <span className="d-block h3 text-white text-center mb-md-0">Start using Mative Cloud</span>
                            
                            <Link to="/" className="btn btn-lg btn-white shadow-3-hover disabled">Coming soon</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mb-5">
            <div className="col-6 col-lg-3 col-xl-2">
                <h6 className="text-white text-uppercase text-sm mb-4">Account</h6>
                
                <ul className="list-unstyled text-sm">
                    <li className="py-1"><Link to={{ pathname: 'https://id.mative.org' }} target="_blank" className="text-white-80 text-white-hover">Profile</Link></li>
                    {/* <li className="py-1"><Link to="/" className="text-white-80 text-white-hover">Settings</Link></li>
                    <li className="py-1"><Link to="/" className="text-white-80 text-white-hover">Billing</Link></li>
                    <li className="py-1"><Link to="/" className="text-white-80 text-white-hover">Notifications</Link></li> */}
                </ul>
            </div>

            <div className="col-6 col-lg">
                <h6 className="text-white text-uppercase text-sm mb-4">Company Details</h6>
                
                <ul className="list-unstyled text-sm">
                    <li className="mb-5 d-flex">
                        <div className="text-base svg-icon align-self-start">
                            <i className="bi bi-map" />
                        </div>

                        <div className="ms-4">
                            <span>
                                Hoog Antink.org<br />
                                C.P.M. Rommelaan 26<br />
                                7103JD, Winterswijk, Netherlands<br />
                                <i>Not a visiting address</i>
                            </span>
                        </div>
                    </li>

                    {/* <li className="mb-5 d-flex">
                        <div className="text-base svg-icon align-self-start">
                            <i className="bi bi-phone"></i>
                        </div>
                        <div className="ms-4">
                            <span>+31 6 123 456 78</span>
                            <span className="d-block text-white-80 text-small">Mon - Fri 11am - 5pm</span>
                        </div>
                    </li> */}

                    <li className="mb-5 d-flex">
                        <div className="text-base svg-icon align-self-start">
                            <i className="bi bi-at"></i>
                        </div>

                        <div className="ms-4">
                            help@mative.org
                        </div>
                    </li>

                    <li className="mb-5 d-flex">
                        <div className="text-base svg-icon align-self-start">
                            <i className="bi bi-briefcase"></i>
                        </div>

                        <div className="ms-4">
                            <span>
                                IBAN: NL53 KNAB 0258 6776 78<br />
                                KvK: 74274783<br />
                            </span>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="col-lg-5 col-xl-4 mt-3 mt-lg-0">
                <h6 className="text-white text-uppercase text-sm mb-4">Subscribe</h6>
                
                <p className="text-white-80">
                    Subscribe to our newsletter and get news about our products.
                </p>
                
                <form className="mt-4 mb-2">
                    <div className="form-row">
                        <div className="col-12">
                            <input type="email" className="form-control mb-2" placeholder="Email Address" name="email" required />
                        </div>

                        <div className="col-12">
                            <Link to="/" className="btn btn-primary w-full">
                                Subscribe
                            </Link>
                        </div>
                    </div>
                </form>
                
                <small className="text-muted form-text">
                    Your data is safe with us. See our <Link to={{ pathname: '/documents/privacy-policy-september-2021.pdf'}} target="_blank" className="link-white">Privacy Policy</Link>
                </small>
            </div>
        </div>

        <div className="row justify-content-center mb-2">
            <div className="col-auto">
                <ul className="nav">
                    <li className="nav-item">
                        <Link to={{ pathname: 'https://github.com/mative' }} target="_blank" className="nav-link text-lg text-white-80 text-white-hover">
                            <figure>
                                <svg width="1em" height="1em" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" className="svg-inline--fa fa-github fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                    <path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path>
                                </svg>
                            </figure>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to={{ pathname: 'https://instagram.com/mativecloud' }} target="_blank" className="nav-link text-lg text-white-80 text-white-hover">
                            <figure>
                                <svg width="1em" height="1em" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" className="svg-inline--fa fa-instagram fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                </svg>
                            </figure>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to={{ pathname: 'https://twitter.com/mativecloud' }} target="_blank" className="nav-link text-lg text-white-80 text-white-hover">
                            <figure>
                                <svg width="1em" height="1em" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className="svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
                                </svg>
                            </figure>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col col-md-auto text-center text-white-80 text-white-hover text-sm">
                Copyright &copy; 2021 <Link to="/" className="h6 text-white-80 text-white-hover text-sm">Mative</Link>. All rights reserved.
            </div>
        </div>
    </div>
</footer>
    );
}

export default Footer;
